import { useDispatch } from "react-redux";
import ExitButton from "./ExitButton";
import { useNavigate } from "react-router-dom";
import { editFestejo, emptyFestejos } from "../store/festejoSlice";
import { logout } from "../store/userSlice";
import { toast } from "react-toastify";
import { IMAGES_DELETED, SERVER_ERROR } from "../constants/messages";
import { deleteImagesFestejo } from "../controllers/festejosCalls";
import "../css/components/ModalConfirmarEliminadoImagenes.css";

function ModalConfirmarEliminadoImagenes({
  id,
  title,
  setImages,
  displayModalConfirmarEliminadoImagenes,
  setDisplayModalConfirmarEliminadoImagenes,
  setBlur,
  setIsLoading,
  token,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickDelete = async () => {
    setIsLoading(true);

    if (!id || !token) {
      dispatch(emptyFestejos());
      dispatch(logout());
      toast.error(SERVER_ERROR);
      navigate("/login");
      return;
    }

    const response = await deleteImagesFestejo(id, token);

    if (!response) {
      dispatch(emptyFestejos());
      dispatch(logout());
      toast.error(SERVER_ERROR);
      navigate("/login");
      return;
    }

    if (!response.success) {
      toast.error(response.message);
      if (response.unauthorized) {
        dispatch(emptyFestejos());
        dispatch(logout());
        navigate("/login");
      }
      setIsLoading(false);
      return;
    }

    dispatch(editFestejo(response.festejo));
    setDisplayModalConfirmarEliminadoImagenes("d-none");
    setImages(response.festejo.images);
    setBlur("");
    setIsLoading(false);
    toast.success(IMAGES_DELETED);
  };

  const onClickCancel = () => {
    setDisplayModalConfirmarEliminadoImagenes("d-none");
    setBlur("");
  };

  return (
    <div
      className={
        "modalConfirmarEliminadoImagenes p-3 w-50 " +
        displayModalConfirmarEliminadoImagenes
      }
    >
      <ExitButton
        setDisplayModal={setDisplayModalConfirmarEliminadoImagenes}
        setBlur={setBlur}
      />
      <h5 className="d-flex justify-content-center mt-4 text-center">
        ¿Está seguro que desea eliminar todas las imágenes del festejo: "{title}
        "?
      </h5>
      <div className="d-flex justify-content-center mt-4">
        <button
          className="btn btn-danger me-5"
          onClick={() => {
            onClickDelete();
          }}
        >
          Eliminar
        </button>
        <button
          className="btn btn-success"
          onClick={() => {
            onClickCancel();
          }}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default ModalConfirmarEliminadoImagenes;
