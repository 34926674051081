import { toast } from "react-toastify";
import ExitButton from "./ExitButton";
import { editFestejoCall } from "../controllers/festejosCalls";
import { FESTEJO_EDITED, SERVER_ERROR } from "../constants/messages";
import { useDispatch } from "react-redux";
import { editFestejo, emptyFestejos } from "../store/festejoSlice";
import { logout } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import "../css/components/ModalEditarFestejo.css";
import ModalConfirmarEliminadoImagen from "./ModalConfirmarEliminadoImagen";
import { useState } from "react";
import { GENERIC_IMAGE } from "../constants/constants";
import ModalConfirmarEliminadoImagenes from "./ModalConfirmarEliminadoImagenes";

function ModalEditarFestejo({
  title,
  setTitle,
  description,
  setDescription,
  id,
  setId,
  images,
  setImages,
  imageVis,
  setImageVis,
  token,
  setDisplayVisImagen,
  displayModalEditarFes,
  setDisplayModalEditarFes,
  setIsLoading,
  setBlur,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [blurEdit, setBlurEdit] = useState("");
  const [indexImage, setIndexImage] = useState(NaN);
  const [displayConfirmarEliminadoImagen, setDisplayConfirmarEliminadoImagen] =
    useState("d-none");

  const [
    displayConfirmarEliminadoImagenes,
    setDisplayConfirmarEliminadoImagenes,
  ] = useState("d-none");

  const onClickOpenModalConfirmar = (index, image) => {
    setIndexImage(index);
    setImageVis(image);
    setDisplayConfirmarEliminadoImagen("");
    setBlurEdit("blur(8px)");
  };

  const onClickOpenModalEliminarImagenes = () => {
    setDisplayConfirmarEliminadoImagenes("");
    setBlurEdit("blur(8px)");
  };

  const onClickOpenVis = (image) => {
    setImageVis(image);
    setDisplayVisImagen("");
  };

  const onSubmitEditFestejo = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!id || !token) {
      toast.error(SERVER_ERROR);
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      return;
    }

    const formData = new FormData(event.target);

    const response = await editFestejoCall(id, token, formData);

    if (!response) {
      toast.error(SERVER_ERROR);
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      return;
    }

    if (!response.success) {
      toast.error(response.message);
      if (response.unauthorized) {
        dispatch(emptyFestejos());
        dispatch(logout());
        navigate("/login");
      }
      setIsLoading(false);
      return;
    }

    dispatch(editFestejo(response.festejo));
    setDescription("");
    setTitle("");
    setId("");
    setImages([]);
    setDisplayModalEditarFes("d-none");
    setBlur("");
    setIsLoading(false);
    toast.success(FESTEJO_EDITED);
  };

  return (
    <div>
      <ModalConfirmarEliminadoImagen
        setBlur={setBlurEdit}
        index={indexImage}
        image={imageVis}
        setImage={setImageVis}
        setIndex={setIndexImage}
        setImages={setImages}
        id={id}
        setDisplayVisImagen={setDisplayVisImagen}
        displayModalConfirmarEliminadoImagen={displayConfirmarEliminadoImagen}
        setDisplayModalConfirmarEliminadoImagen={
          setDisplayConfirmarEliminadoImagen
        }
        setIsLoading={setIsLoading}
        token={token}
      />
      <ModalConfirmarEliminadoImagenes
        id={id}
        title={title}
        setImages={setImages}
        displayModalConfirmarEliminadoImagenes={
          displayConfirmarEliminadoImagenes
        }
        setDisplayModalConfirmarEliminadoImagenes={
          setDisplayConfirmarEliminadoImagenes
        }
        setBlur={setBlurEdit}
        setIsLoading={setIsLoading}
        token={token}
      />
      <div
        className={"modalEditarFestejo p-3 w-50 " + displayModalEditarFes}
        style={{
          bottom: images.length < 3 ? "20%" : images.length >= 3 ? "10%" : "5%",
          filter: blurEdit,
        }}
      >
        <ExitButton
          setDescription={setDescription}
          setTitle={setTitle}
          setId={setId}
          setImages={setImages}
          setDisplayModal={setDisplayModalEditarFes}
          setBlur={setBlur}
        />
        <h3 className="d-flex justify-content-center">Editar festejo</h3>
        <form
          onSubmit={(event) => {
            onSubmitEditFestejo(event);
          }}
          className="mt-2"
        >
          <div className="form-group">
            <label htmlFor="title" className="mb-1">
              <strong>Título</strong>
            </label>
            <input
              type="text"
              placeholder="Título..."
              className="form-control"
              name="title"
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              value={title}
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="description" className="mb-1">
              <strong>Descripción</strong>
            </label>
            <textarea
              placeholder="Descripción..."
              className="form-control"
              name="description"
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              value={description}
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="images" className="mb-1">
              <strong>Fotos</strong>
            </label>
            {images.map((image, index) => {
              return (
                <div key={index} className="d-flex">
                  <input
                    type="file"
                    className="form-control mb-1"
                    name={"image" + (index + 1)}
                  />
                  <div className="d-flex">
                    {image !== GENERIC_IMAGE ? (
                      <button
                        className="btn btn-danger ms-1"
                        type="button"
                        onClick={() => {
                          onClickOpenModalConfirmar(index, image);
                        }}
                      >
                        X
                      </button>
                    ) : null}
                    <button
                      className="btn btn-primary ms-1"
                      type="button"
                      onClick={() => {
                        onClickOpenVis(image);
                      }}
                    >
                      Ver
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-between mt-2 px-2">
            <button type="submit" className="btn btn-success">
              Editar
            </button>
            {images[0] !== GENERIC_IMAGE ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  onClickOpenModalEliminarImagenes();
                }}
              >
                Eliminar imágenes
              </button>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ModalEditarFestejo;
