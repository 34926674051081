const GET = "GET";
const POST = "POST";
const PATCH = "PATCH";
const DELETE = "DELETE";
const BEARER = "Bearer ";
const FESTEJO = "festejo";
const USER = "user";
const LOGIN = "login";
const IMAGE = "image";
const MULTIPART_FORM_DATA = "multipart/form-data";
const GENERIC_IMAGE = "background2.jpg";
const UNAUTHORIZED_STATUS = 401;
const SERVER_ERROR_STATUS = 500;

module.exports = {
  GET,
  POST,
  PATCH,
  DELETE,
  FESTEJO,
  USER,
  LOGIN,
  IMAGE,
  UNAUTHORIZED_STATUS,
  BEARER,
  GENERIC_IMAGE,
  MULTIPART_FORM_DATA,
  SERVER_ERROR_STATUS,
};
