import "../css/components/FestejoContainer.css";
import { useState } from "react";
import greenArrow from "../images/greenArrow.png";

function FestejoContainer({ festejo }) {
  const [index, setIndex] = useState(0);
  const [displayRightArrow, setDisplayRightArrow] = useState("");
  const [displayLeftArrow, setDisplayLeftArrow] = useState(0);

  const onClickChangeImage = (isRight) => {
    if (festejo.images.length === 0) return;

    if (isRight) {
      if (index === festejo.images.length - 1) {
        setDisplayRightArrow(0);
        return;
      }
      if (index === 0) setDisplayLeftArrow("");
      if (index === festejo.images.length - 2) setDisplayRightArrow(0);
      setIndex(index + 1);
    } else {
      if (index === 0) {
        setDisplayLeftArrow(0);
        return;
      }
      if (index === festejo.images.length - 1) setDisplayRightArrow("");
      if (index === 1) setDisplayLeftArrow(0);
      setIndex(index - 1);
    }
  };

  return (
    <div className="festejoContainer d-flex flex-column align-items-center">
      <h4 className="festejoTitles">
        <strong>{festejo.title}</strong>
      </h4>
      <p className="festejoParagraph">{festejo.paragraph}</p>
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={greenArrow}
          alt="flecha"
          style={{ opacity: displayLeftArrow }}
          className="arrowFest me-2"
          onClick={() => {
            onClickChangeImage(false);
          }}
        />
        <img
          src={process.env.REACT_APP_IMAGES_URL + festejo.images[index]}
          alt={festejo.images[index]}
          className="festejoImage"
        />
        <img
          src={greenArrow}
          alt="flecha"
          className="arrowFest rightFest"
          style={{
            opacity: festejo.images.length === 1 ? 0 : displayRightArrow,
          }}
          onClick={() => {
            onClickChangeImage(true);
          }}
        />
      </div>
    </div>
  );
}

export default FestejoContainer;
