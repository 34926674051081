import "../css/components/ModalInstrucciones.css";
import ExitButton from "./ExitButton";

function ModalInstrucciones({
  displayModalInstrucciones,
  setDisplayModalInstrucciones,
  setBlur,
}) {
  return (
    <div className={"modalInstrucciones p-3 w-50 " + displayModalInstrucciones}>
      <ExitButton
        setDisplayModal={setDisplayModalInstrucciones}
        setBlur={setBlur}
      />
      <h3 className="d-flex justify-content-center">Instrucciones de uso</h3>
      <p>
        <strong>Cambiar contraseña:</strong> Nueva contraseña, repetir nueva
        contraseña, contraseña actual.
        <br />
        <strong>Agregar festejo:</strong> Titulo y descripción obligatoria
        (Avisar si quiere descripción no obligatoria) Imágenes: 1 por input,
        máximo 5 En caso de no agregar imágenes, se agregar imagen genérica.
        <br />
        <strong>Editar festejo:</strong> En caso de querer mantener titulo o
        descripción, dejar el valor como este. En caso de subir archivos, se
        sustituira la imagen selecciona (clickear ver para chequear la imagén)
        En caso de presionar la X, se eliminará la foto. Agregar imágenes:
        Apareceran solo las faltantes antes de llegar a 5, no es posible agregar
        más de 5. Eliminar imágenes: se eliminaran todas las imágenes asociadas
        al festejo, y se dejará la genérica.
        <br />
        <strong>Eliminar festejo:</strong> se elimina el festejo.
        <br />
        En caso de querer acceder a la página de administrador
        (https://residencialmamina.com.uy/admin) sin loguearse antes, aparecerá
        un aviso de no encontrado. Es necesario loguearse para que aparezca la
        página
      </p>
    </div>
  );
}

export default ModalInstrucciones;
