import { toast } from "react-toastify";
import { changePassword } from "../controllers/userCalls";
import "../css/components/ModalCambiarContraseña.css";
import { useDispatch } from "react-redux";
import { logout } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import { emptyFestejos } from "../store/festejoSlice";
import ExitButton from "./ExitButton";
import {
  MISSING_DATA,
  PASSWORD_CHANGED,
  SERVER_ERROR,
  UNMATCHING_PASSWORDS,
} from "../constants/messages";

function ModalCambiarContraseña({
  id,
  token,
  displayModalCamCon,
  setDisplayModalCamCon,
  setIsLoading,
  setBlur,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitChangePassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!id || !token) {
      toast.error(SERVER_ERROR);
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      return;
    }

    const newPassword = event.target.newPassword.value;
    const repeatPassword = event.target.repeatPassword.value;
    const password = event.target.password.value;

    if (!newPassword || !repeatPassword || !password) {
      toast.warning(MISSING_DATA);
      setIsLoading(false);
      return;
    }

    if (newPassword !== repeatPassword) {
      toast.error(UNMATCHING_PASSWORDS);
      setIsLoading(false);
      return;
    }

    const response = await changePassword(newPassword, password, id, token);

    if (!response) {
      toast.error(SERVER_ERROR);
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      return;
    }

    if (!response.success) {
      toast.error(response.message);
      if (response.unauthorized) {
        dispatch(emptyFestejos());
        dispatch(logout());
        navigate("/login");
      }
      setIsLoading(false);
      return;
    }

    toast.success(PASSWORD_CHANGED);
    setDisplayModalCamCon("d-none");
    setBlur(null);
    setIsLoading(false);
  };

  return (
    <div className={"modalCambiarContraseña p-3 w-50 " + displayModalCamCon}>
      <ExitButton setDisplayModal={setDisplayModalCamCon} setBlur={setBlur} />
      <h3 className="d-flex justify-content-center">Cambiar contraseña</h3>
      <form
        onSubmit={(event) => {
          onSubmitChangePassword(event);
        }}
        className="mt-2"
      >
        <div className="form-group">
          <label htmlFor="newPassword" className="mb-1">
            <strong>Nueva contraseña</strong>
          </label>
          <input
            type="password"
            placeholder="Nueva contraseña..."
            className="form-control"
            name="newPassword"
          />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="repeatPassword" className="mb-1">
            <strong>Repetir contraseña</strong>
          </label>
          <input
            type="password"
            placeholder="Repetir contraseña..."
            className="form-control"
            name="repeatPassword"
          />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="password" className="mb-1">
            <strong>Contraseña</strong>
          </label>
          <input
            type="password"
            placeholder="Contraseña..."
            className="form-control"
            name="password"
          />
        </div>
        <button type="submit" className="btn btn-success mt-2">
          Cambiar
        </button>
      </form>
    </div>
  );
}

export default ModalCambiarContraseña;
