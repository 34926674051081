const SERVER_ERROR = "Ha habido un error, intente iniciar sesion nuevamente";
const UNAUTHORIZED_MESSAGE = "Su sesión ha expirado, inicie sesión nuevamente";
const MISSING_DATA = "Ingrese todos los datos";
const UNMATCHING_PASSWORDS = "Las contraseñas no coinciden";
const PASSWORD_CHANGED = "Contraseña cambiada correctamente";
const LOGGED = "Sesión iniciada";
const FESTEJO_CREATED = "Festejo creado correctamente";
const FESTEJO_DELETED = "Festejo eliminado correctamente";
const FESTEJO_EDITED = "Festejo editado correctamente";
const IMAGE_DELETED = "Imagen eliminada correctamente";
const IMAGES_DELETED = "Imágenes eliminadas correctamente";

module.exports = {
  UNAUTHORIZED_MESSAGE,
  MISSING_DATA,
  UNMATCHING_PASSWORDS,
  SERVER_ERROR,
  PASSWORD_CHANGED,
  LOGGED,
  FESTEJO_CREATED,
  FESTEJO_DELETED,
  FESTEJO_EDITED,
  IMAGE_DELETED,
  IMAGES_DELETED,
};
