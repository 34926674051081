import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const token = user.token;

  useEffect(() => {
    if (!token) navigate("/notFound");
  }, []);
  return children;
}

export default ProtectedRoute;
