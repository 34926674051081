import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Instalaciones from "./pages/Instalaciones";
import Menus from "./pages/Menus";
import NotFound from "./pages/NotFound";
import SobreNosotros from "./pages/SobreNosotros";
import Servicios from "./pages/Servicios";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Festejos from "./pages/Festejos";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./routes/ProtectedRoute";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="servicios" element={<Servicios />} />
        <Route path="instalaciones" element={<Instalaciones />} />
        <Route path="menus" element={<Menus />} />
        <Route path="aboutus" element={<SobreNosotros />} />
        <Route path="festejos" element={<Festejos />} />
        <Route path="login" element={<Login />} />
        <Route
          path="admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
