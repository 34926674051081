import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/pages/NotFound.css";

function NotFound() {
  return (
    <div>
      <Header />
      <section className="notFound d-flex justify-content-center align-items-center">
        <div className="notFoundBox d-flex justify-content-center align-items-center">
          <h4 className="notFoundPhrase">
            <strong>Ups... No encontramos lo que estabas buscando</strong>
          </h4>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default NotFound;
