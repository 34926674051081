import { useDispatch, useSelector } from "react-redux";
import { callFestejos, emptyFestejos } from "../store/festejoSlice";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/pages/Admin.css";
import { useEffect, useState } from "react";
import { getFestejosCall } from "../controllers/festejosCalls";
import Table from "react-bootstrap/Table";
import { logout } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import ModalCambiarContraseña from "../components/ModalCambiarContraseña";
import ModalCrearFestejo from "../components/ModalCrearFestejo";
import ModalEditarFestejo from "../components/ModalEditarFestejo";
import ModalConfirmarEliminado from "../components/ModalConfirmarEliminado";
import ModalAgregarImagenes from "../components/ModalAgregarImagenes";
import VisualizarImagen from "../components/VisualizarImagen";
import ModalInstrucciones from "../components/ModalInstrucciones";

function Admin() {
  const [isLoading, setIsLoading] = useState(true);

  const festejos = useSelector((state) => state.festejo);
  const user = useSelector((state) => state.user);
  const token = user.token;
  const id = user.id;

  const [blur, setBlur] = useState("");
  const [displayModalInstrucciones, setDisplayModalInstrucciones] =
    useState("d-none");
  const [displayModalCamCon, setDisplayModalCamCon] = useState("d-none");
  const [displayModalNuevoFes, setDisplayModalNuevoFes] = useState("d-none");
  const [displayModalEditarFes, setDisplayModalEditarFes] = useState("d-none");
  const [displayModalConfirmarEliminado, setDisplayModalConfirmarEliminado] =
    useState("d-none");
  const [displayModalAgregarImagenes, setDisplayModalAgregarImagenes] =
    useState("d-none");
  const [displayVisImagen, setDisplayVisImagen] = useState("d-none");

  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editId, setEditId] = useState("");
  const [editImages, setEditImages] = useState([]);

  const [addImageTitle, setAddImageTitle] = useState("");
  const [addImageId, setAddImageId] = useState("");
  const [amountImages, setAmountImages] = useState([]);

  const [deleteId, setDeleteId] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");

  const [visImage, setVisImage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const response = await getFestejosCall();
      dispatch(callFestejos(response));
      setIsLoading(false);
    };
    getData();
  }, []);

  const onClickOpenModalInstrucciones = () => {
    setDisplayModalInstrucciones("");
    setBlur("blur(8px)");
  };

  const onClickOpenModalCamCon = () => {
    setDisplayModalCamCon("");
    setBlur("blur(8px)");
  };

  const onClickOpenModalNuevoFes = () => {
    setDisplayModalNuevoFes("");
    setBlur("blur(8px)");
  };

  const onClickLogout = () => {
    dispatch(emptyFestejos());
    dispatch(logout());
    navigate("/login");
  };

  const onClickOpenModalEditarFes = (id, title, description, images) => {
    setEditId(id);
    setEditDescription(description);
    setEditTitle(title);
    setEditImages(images);
    setDisplayModalEditarFes("");
    setBlur("blur(8px)");
  };

  const onClickOpenModalAgregarImagenes = (id, title, images) => {
    setAddImageId(id);
    setAddImageTitle(title);
    let amountOfImages = [];
    if (images[0] === "background2.jpg") amountOfImages = [1, 2, 3, 4, 5];
    else {
      let amount = 5 - images.length;
      for (let i = 0; i < amount; i++) {
        amountOfImages.push(i + 1);
      }
    }
    setAmountImages(amountOfImages);
    setDisplayModalAgregarImagenes("");
    setBlur("blur(8px)");
  };

  const onClickOpenModalConfEliminado = (id, title) => {
    setDeleteId(id);
    setDeleteTitle(title);
    setDisplayModalConfirmarEliminado("");
    setBlur("blur(8px)");
  };

  return isLoading ? (
    <div>
      <Header />
      <div
        className="admin d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div
          className="adminBox p-5 d-flex justify-content-center align-items-center"
          style={{ height: "80%" }}
        >
          <Spinner />
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div>
      <ModalInstrucciones
        displayModalInstrucciones={displayModalInstrucciones}
        setDisplayModalInstrucciones={setDisplayModalInstrucciones}
        setBlur={setBlur}
      />
      <ModalCambiarContraseña
        id={id}
        token={token}
        displayModalCamCon={displayModalCamCon}
        setDisplayModalCamCon={setDisplayModalCamCon}
        setIsLoading={setIsLoading}
        setBlur={setBlur}
      />
      <ModalCrearFestejo
        token={token}
        displayModalNuevoFes={displayModalNuevoFes}
        setDisplayModalNuevoFes={setDisplayModalNuevoFes}
        setIsLoading={setIsLoading}
        setBlur={setBlur}
      />
      <ModalAgregarImagenes
        title={addImageTitle}
        setTitle={setAddImageTitle}
        id={addImageId}
        setId={setAddImageId}
        token={token}
        amountImages={amountImages}
        setAmountImages={setAmountImages}
        setBlur={setBlur}
        setIsLoading={setIsLoading}
        displayModalAgregarImagenes={displayModalAgregarImagenes}
        setDisplayModalAgregarImagenes={setDisplayModalAgregarImagenes}
      />
      <ModalEditarFestejo
        title={editTitle}
        setTitle={setEditTitle}
        description={editDescription}
        setDescription={setEditDescription}
        id={editId}
        setId={setEditId}
        images={editImages}
        setImages={setEditImages}
        imageVis={visImage}
        setImageVis={setVisImage}
        token={token}
        setDisplayVisImagen={setDisplayVisImagen}
        displayModalEditarFes={displayModalEditarFes}
        setDisplayModalEditarFes={setDisplayModalEditarFes}
        setIsLoading={setIsLoading}
        setBlur={setBlur}
      />
      <ModalConfirmarEliminado
        id={deleteId}
        setId={setDeleteId}
        token={token}
        title={deleteTitle}
        setTitle={setDeleteTitle}
        displayModalConfirmarEliminado={displayModalConfirmarEliminado}
        setDisplayModalConfirmarEliminado={setDisplayModalConfirmarEliminado}
        setIsLoading={setIsLoading}
        setBlur={setBlur}
      />
      <VisualizarImagen
        image={visImage}
        setImage={setVisImage}
        displayVis={displayVisImagen}
        setDisplayVis={setDisplayVisImagen}
      />
      <div style={{ filter: blur }}>
        <Header />
        <section
          className="admin d-flex justify-content-center align-items-center"
          style={{
            height: festejos.length > 3 ? "" : "100vh",
            padding: festejos.length > 3 ? "2.5% 0" : "",
          }}
        >
          <div
            className="adminBox p-5"
            style={{ height: festejos.length > 3 ? "" : "80%" }}
          >
            <h3 className="adminTitle d-flex justify-content-center">
              <strong>Administración</strong>
            </h3>
            <div className="d-flex justify-content-center my-5">
              <button
                type="button"
                className="btn btn-secondary me-1"
                onClick={() => {
                  onClickOpenModalInstrucciones();
                }}
              >
                Instrucciones
              </button>
              <button
                type="button"
                className="btn btn-primary me-1"
                onClick={() => {
                  onClickOpenModalCamCon();
                }}
              >
                Cambiar contraseña
              </button>
              <button
                type="button"
                className="btn btn-success me-1"
                onClick={() => {
                  onClickOpenModalNuevoFes();
                }}
              >
                Crear festejo
              </button>
              <button
                type="button"
                className="btn btn-danger me-1"
                onClick={() => {
                  onClickLogout();
                }}
              >
                Cerrar sesión
              </button>
            </div>
            <h4 className="adminTitle d-flex justify-content-center">
              Festejos
            </h4>
            {festejos.length > 0 ? (
              <Table striped bordered hover>
                <thead className="thead-light">
                  <tr>
                    <th scope="row" className="col-1">
                      #
                    </th>
                    <th className="col-6 text-center">Título</th>
                    <th className="col-5 text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {festejos.map((festejo, index) => {
                    return (
                      <tr key={festejo._id}>
                        <th>{index + 1}</th>
                        <td className="text-center">{festejo.title}</td>
                        <td className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-warning me-2"
                            onClick={() => {
                              onClickOpenModalEditarFes(
                                festejo._id,
                                festejo.title,
                                festejo.paragraph,
                                festejo.images
                              );
                            }}
                          >
                            Editar
                          </button>
                          {festejo.images.length < 5 ? (
                            <button
                              type="button"
                              className="btn btn-success me-2"
                              onClick={() => {
                                onClickOpenModalAgregarImagenes(
                                  festejo._id,
                                  festejo.title,
                                  festejo.images
                                );
                              }}
                            >
                              Agregar imagén
                            </button>
                          ) : null}
                          <button
                            type="button"
                            className="btn btn-danger me-2"
                            onClick={() => {
                              onClickOpenModalConfEliminado(
                                festejo._id,
                                festejo.title
                              );
                            }}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <h2 className="d-flex justify-content-center align-items-center h-50 adminTitle">
                No se han encontrado festejos
              </h2>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Admin;
