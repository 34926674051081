import { createSlice } from "@reduxjs/toolkit";

const festejoSlice = createSlice({
  name: "festejo",
  initialState: [],
  reducers: {
    callFestejos(state, action) {
      return action.payload;
    },
    addFestejo(state, action) {
      return [...state, action.payload];
    },
    editFestejo(state, action) {
      return state.map((festejo) => {
        return festejo._id === action.payload._id ? action.payload : festejo;
      });
    },
    deleteFestejo(state, action) {
      return state.filter((festejo) => {
        return festejo._id !== action.payload;
      });
    },
    emptyFestejos(state, action) {
      return [];
    },
  },
});

export const {
  callFestejos,
  editFestejo,
  addFestejo,
  deleteFestejo,
  emptyFestejos,
} = festejoSlice.actions;
export default festejoSlice.reducer;
