import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/pages/Login.css";
import { getToken } from "../controllers/userCalls";
import { useDispatch } from "react-redux";
import { login } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LOGGED, MISSING_DATA, SERVER_ERROR } from "../constants/messages";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (event) => {
    event.preventDefault();

    const username = event.target.username.value;
    const password = event.target.password.value;

    if (!username || !password) {
      toast.warning(MISSING_DATA);
      return;
    }

    const response = await getToken(username, password);

    if (!response) {
      toast.error(SERVER_ERROR);
      return;
    }

    if (response.message) {
      toast.error(response.message);
      return;
    }

    dispatch(login({ token: response.token, id: response.id }));
    toast.success(LOGGED);
    navigate("/admin");
  };

  return (
    <div>
      <Header />
      <section className="login d-flex justify-content-center align-items-center">
        <div className="loginModal p-3">
          <h5>Iniciar sesión</h5>
          <form
            className="d-flex flex-column"
            onSubmit={(event) => {
              onSubmit(event);
            }}
          >
            <div className="form-group mt-2">
              <label className="mb-1">
                <strong>Usuario</strong>
              </label>
              <input
                type="text"
                placeholder="Usuario..."
                className="form-control"
                name="username"
              />
            </div>
            <div className="form-group mt-2 mb-2">
              <label className="mb-1">
                <strong>Contraseña</strong>
              </label>
              <input
                type="password"
                placeholder="Contraseña..."
                className="form-control"
                name="password"
              />
            </div>
            <button type="submit" className="btn btn-success mt-3">
              Ingresar
            </button>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Login;
