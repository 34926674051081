import "../css/components/VisualizarImagen.css";
import ExitButton from "./ExitButton";

function VisulizarImagen({ displayVis, setDisplayVis, image, setImage }) {
  return (
    <div className={"p-5 visualizarImagen " + displayVis}>
      <div className="d-flex justify-content-end">
        <ExitButton
          setDisplayModal={setDisplayVis}
          setImage={setImage}
          light={true}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center p-5">
        <img
          src={process.env.REACT_APP_IMAGES_URL + image}
          alt={image}
          className="visImage"
        />
      </div>
    </div>
  );
}

export default VisulizarImagen;
