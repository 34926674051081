import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callFestejos } from "../store/festejoSlice";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/pages/Festejos.css";
import { getFestejosCall } from "../controllers/festejosCalls";
import { Spinner } from "react-bootstrap";
import FestejoContainer from "../components/FestejoContainer";

function Festejos() {
  const [isLoading, setIsLoading] = useState(true);

  const festejos = useSelector((state) => state.festejo);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const response = await getFestejosCall();

      dispatch(callFestejos(response));
      setIsLoading(false);
    };
    getData();
  }, []);

  return isLoading ? (
    <div>
      <Header page={"Celebraciones"} />
      <div
        className="festejos d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div
          className="festejosBox p-5 d-flex justify-content-center align-items-center"
          style={{ height: "80%" }}
        >
          <Spinner />
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div>
      <Header page={"Celebraciones"} />
      <section className="festejos d-flex justify-content-center align-items-center">
        <div className="festejosBox">
          <h3 className="celebracionesTitles mb-4">
            <strong>CELEBRACIONES</strong>
          </h3>
          {festejos.map((festejo, index) => {
            return <FestejoContainer key={index} festejo={festejo} />;
          })}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Festejos;
