function ExitButton({
  light,
  setDisplayModal,
  setTitle,
  setDescription,
  setId,
  setImages,
  setIndex,
  setImage,
  setBlur,
}) {
  const onClickExit = () => {
    if (setDisplayModal) setDisplayModal("d-none");
    if (setTitle) setTitle("");
    if (setDescription) setDescription("");
    if (setId) setId("");
    if (setImages) setImages([]);
    if (setIndex) setIndex(NaN);
    if (setImage) setImage("");
    if (setBlur) setBlur("");
  };

  return (
    <div className="d-flex justify-content-end">
      <button
        type="button"
        className={light ? "btn btn-light" : "btn btn-dark"}
        onClick={() => {
          onClickExit();
        }}
      >
        <strong>x</strong>
      </button>
    </div>
  );
}

export default ExitButton;
