import axios from "axios";
import {
  POST,
  LOGIN,
  USER,
  PATCH,
  UNAUTHORIZED_STATUS,
  UNAUTHORIZED_MESSAGE,
  BEARER,
} from "../constants/constants";

const url = process.env.REACT_APP_BACK_URL + "/" + USER;

async function getToken(username, password) {
  if (!username || !password) {
    return null;
  }
  try {
    const response = await axios({
      url: url + "/" + LOGIN,
      method: POST,
      data: { username, password },
    });
    return { token: response.data.token, id: response.data.id };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    return response.data;
  }
}

async function changePassword(newPassword, password, id, token) {
  if (!newPassword || !password || !id || !token) {
    return null;
  }

  try {
    await axios({
      method: PATCH,
      url: url + "/" + id,
      headers: { Authorization: BEARER + token },
      data: {
        newPassword,
        password,
      },
    });
    return { success: true };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    let message = response.data.message;
    let unauthorized = false;

    if (response.status === UNAUTHORIZED_STATUS) {
      message = UNAUTHORIZED_MESSAGE;
      unauthorized = true;
    }

    return { success: false, message, unauthorized };
  }
}

export { getToken, changePassword };
