import { toast } from "react-toastify";
import "../css/components/ModalCrearFestejo.css";
import { useDispatch } from "react-redux";
import { logout } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import { addFestejo, emptyFestejos } from "../store/festejoSlice";
import ExitButton from "./ExitButton";
import {
  FESTEJO_CREATED,
  MISSING_DATA,
  SERVER_ERROR,
} from "../constants/messages";
import { createFestejoCall } from "../controllers/festejosCalls";
import { MULTIPART_FORM_DATA } from "../constants/constants";

function ModalCrearFestejo({
  token,
  displayModalNuevoFes,
  setDisplayModalNuevoFes,
  setIsLoading,
  setBlur,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitCreateFestejo = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!token) {
      toast.error(SERVER_ERROR);
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      return;
    }

    const title = event.target.title.value;
    const paragraph = event.target.description.value;

    if (!title || !paragraph) {
      toast.warning(MISSING_DATA);
      setIsLoading(false);
      return;
    }

    const formData = new FormData(event.target);

    const response = await createFestejoCall(token, formData);

    if (!response) {
      toast.error(SERVER_ERROR);
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      return;
    }

    if (!response.success) {
      toast.error(response.message);
      if (response.unauthorized) {
        dispatch(emptyFestejos());
        dispatch(logout());
        navigate("/login");
      }
      setIsLoading(false);
      return;
    }

    dispatch(addFestejo(response.festejo));
    setDisplayModalNuevoFes("d-none");
    setBlur(null);
    setIsLoading(false);
    toast.success(FESTEJO_CREATED);
  };

  return (
    <div className={"modalCrearFestejo p-3 w-50 " + displayModalNuevoFes}>
      <ExitButton setDisplayModal={setDisplayModalNuevoFes} setBlur={setBlur} />
      <h3 className="d-flex justify-content-center">Crear festejo</h3>
      <form
        encType={MULTIPART_FORM_DATA}
        onSubmit={(event) => {
          onSubmitCreateFestejo(event);
        }}
        className="mt-2"
      >
        <div className="form-group">
          <label htmlFor="title" className="mb-1">
            <strong>Título</strong>
          </label>
          <input
            type="text"
            placeholder="Título..."
            className="form-control"
            name="title"
          />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="description" className="mb-1">
            <strong>Descripción</strong>
          </label>
          <textarea
            placeholder="Descripción..."
            className="form-control"
            name="description"
          />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="images" className="mb-1">
            <strong>Fotos</strong>
          </label>
          <input type="file" className="form-control mb-1" name="image1" />
          <input type="file" className="form-control mb-1" name="image2" />
          <input type="file" className="form-control mb-1" name="image3" />
          <input type="file" className="form-control mb-1" name="image4" />
          <input type="file" className="form-control mb-1" name="image5" />
        </div>
        <button type="submit" className="btn btn-success mt-2">
          Crear
        </button>
      </form>
    </div>
  );
}

export default ModalCrearFestejo;
