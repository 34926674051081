import axios from "axios";
import {
  GET,
  FESTEJO,
  DELETE,
  POST,
  UNAUTHORIZED_STATUS,
  BEARER,
  PATCH,
  MULTIPART_FORM_DATA,
  IMAGE,
  SERVER_ERROR_STATUS,
} from "../constants/constants";
import { UNAUTHORIZED_MESSAGE } from "../constants/messages";

const url = process.env.REACT_APP_BACK_URL + "/" + FESTEJO;

async function getFestejosCall() {
  try {
    const response = await axios({
      method: GET,
      url: url,
    });

    return response.data.festejos.reverse();
  } catch (error) {
    return [];
  }
}

async function createFestejoCall(token, formData) {
  if (!token || !formData) return null;

  try {
    const response = await axios({
      method: POST,
      url: url,
      headers: {
        Authorization: BEARER + token,
        "Content-type": MULTIPART_FORM_DATA,
      },
      data: formData,
    });

    if (!response) return null;

    const festejo = response.data.festejo;

    if (!festejo) return null;

    return { success: true, festejo };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    let message = response.data.message;
    let unauthorized = false;

    if (response.status === UNAUTHORIZED_STATUS) {
      message = UNAUTHORIZED_MESSAGE;
      unauthorized = true;
    }

    return { success: false, message, unauthorized };
  }
}

async function addImageFestejo(id, token, formData) {
  if (!id || !token || !formData) return null;

  try {
    const response = await axios({
      url: url + "/" + IMAGE + "/" + id,
      method: POST,
      headers: {
        Authorization: BEARER + token,
        "Content-Type": MULTIPART_FORM_DATA,
      },
      data: formData,
    });

    if (!response) return null;

    const festejo = response.data.festejo;

    if (!festejo) return null;

    return { success: true, festejo };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    let message = response.data.message;
    let unauthorized = false;

    if (response.status === UNAUTHORIZED_STATUS) {
      message = UNAUTHORIZED_MESSAGE;
      unauthorized = true;
    }

    return { success: false, message, unauthorized };
  }
}

async function deleteImageFestejo(index, id, token) {
  if ((index !== 0 && !index) || !id || !token) return null;

  try {
    const response = await axios({
      method: PATCH,
      url: url + "/" + IMAGE + "/" + id,
      headers: {
        Authorization: BEARER + token,
      },
      data: {
        index,
      },
    });

    if (!response) return null;

    const festejo = response.data.festejo;

    if (!festejo) return null;

    return { success: true, festejo };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    let message = response.data.message;
    let unauthorized = false;

    if (response.status === UNAUTHORIZED_STATUS) {
      message = UNAUTHORIZED_MESSAGE;
      unauthorized = true;
    }

    return { success: false, message, unauthorized };
  }
}

async function deleteImagesFestejo(id, token) {
  if (!id || !token) return null;

  try {
    const response = await axios({
      url: url + "/" + IMAGE + "/" + id,
      method: DELETE,
      headers: { Authorization: BEARER + token },
    });

    if (response.status === SERVER_ERROR_STATUS)
      return { success: false, message: response.data.message };

    const festejo = response.data.festejo;

    if (!festejo) return null;

    return { success: true, festejo };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    let message = response.data.message;
    let unauthorized = false;

    if (response.status === UNAUTHORIZED_STATUS) {
      message = UNAUTHORIZED_MESSAGE;
      unauthorized = true;
    }

    return { success: false, message, unauthorized };
  }
}

async function editFestejoCall(id, token, formData) {
  if (!id || !token || !formData) return null;

  try {
    const response = await axios({
      method: PATCH,
      url: url + "/" + id,
      headers: {
        Authorization: BEARER + token,
        "Content-Type": MULTIPART_FORM_DATA,
      },
      data: formData,
    });

    const festejo = response.data.festejo;

    if (!festejo) return { success: false, message: response.data.message };

    return { success: true, festejo: response.data.festejo };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    let message = response.data.message;
    let unauthorized = false;

    if (response.status === UNAUTHORIZED_STATUS) {
      message = UNAUTHORIZED_MESSAGE;
      unauthorized = true;
    }

    return { success: false, message, unauthorized };
  }
}

async function deleteFestejoCall(id, token) {
  if (!id || !token) return null;

  try {
    await axios({
      url: url + "/" + id,
      method: DELETE,
      headers: {
        Authorization: BEARER + token,
      },
    });

    return { success: true };
  } catch (error) {
    const response = error.response;

    if (!response) return null;

    let message = response.data.message;
    let unauthorized = false;

    if (response.status === UNAUTHORIZED_STATUS) {
      message = UNAUTHORIZED_MESSAGE;
      unauthorized = true;
    }

    return { success: false, message, unauthorized };
  }
}

export {
  getFestejosCall,
  createFestejoCall,
  addImageFestejo,
  deleteImageFestejo,
  deleteImagesFestejo,
  editFestejoCall,
  deleteFestejoCall,
};
