import { useDispatch } from "react-redux";
import ExitButton from "./ExitButton";
import { logout } from "../store/userSlice";
import { deleteFestejo, emptyFestejos } from "../store/festejoSlice";
import { toast } from "react-toastify";
import "../css/components/ModalConfirmarEliminado.css";
import { deleteFestejoCall } from "../controllers/festejosCalls";
import { useNavigate } from "react-router-dom";
import { FESTEJO_DELETED, SERVER_ERROR } from "../constants/messages";

function ModalConfirmarEliminado({
  id,
  setId,
  token,
  title,
  setTitle,
  displayModalConfirmarEliminado,
  setDisplayModalConfirmarEliminado,
  setIsLoading,
  setBlur,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickDelete = async () => {
    setIsLoading(true);
    const response = await deleteFestejoCall(id, token);

    if (!response) {
      dispatch(logout());
      dispatch(emptyFestejos());
      toast.error(SERVER_ERROR);
      navigate("/login");
      return;
    }

    if (!response.success) {
      setDisplayModalConfirmarEliminado("d-none");
      setBlur("");
      setId("");
      setTitle("");
      if (response.unauthorized) {
        dispatch(logout());
        dispatch(emptyFestejos());
        navigate("/login");
      }
      setIsLoading(false);
      toast.error(response.message);
      return;
    }

    dispatch(deleteFestejo(id));
    setId("");
    setTitle("");
    setDisplayModalConfirmarEliminado("d-none");
    setBlur("");
    setIsLoading(false);
    toast.success(FESTEJO_DELETED);
  };

  const onClickCancel = () => {
    setId("");
    setTitle("");
    setDisplayModalConfirmarEliminado("d-none");
    setBlur("");
  };

  return (
    <div
      className={
        "modalConfirmarEliminado p-3 w-50 " + displayModalConfirmarEliminado
      }
    >
      <ExitButton
        setDisplayModal={setDisplayModalConfirmarEliminado}
        setBlur={setBlur}
        setId={setId}
        setTitle={setTitle}
      />
      <h5 className="d-flex justify-content-center mt-4 text-center">
        ¿Está seguro que desea eliminar el festejo: "{title}" ?
      </h5>
      <div className="d-flex justify-content-center mt-4">
        <button
          className="btn btn-danger me-5"
          onClick={() => {
            onClickDelete();
          }}
        >
          Eliminar
        </button>
        <button
          className="btn btn-success"
          onClick={() => {
            onClickCancel();
          }}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default ModalConfirmarEliminado;
