import { useDispatch } from "react-redux";
import { editFestejo, emptyFestejos } from "../store/festejoSlice";
import { logout } from "../store/userSlice";
import { toast } from "react-toastify";
import { SERVER_ERROR } from "../constants/messages";
import { useNavigate } from "react-router-dom";
import { addImageFestejo } from "../controllers/festejosCalls";
import ExitButton from "./ExitButton";
import "../css/components/ModalAgregarImagenes.css";

function ModalAgregarImagenes({
  title,
  setTitle,
  id,
  setId,
  amountImages,
  setAmountImages,
  displayModalAgregarImagenes,
  setDisplayModalAgregarImagenes,
  setIsLoading,
  setBlur,
  token,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitAgregarImagenes = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!token || !id) {
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      toast(SERVER_ERROR);
    }

    const formData = new FormData(event.target);

    const response = await addImageFestejo(id, token, formData);

    if (!response) {
      dispatch(emptyFestejos());
      dispatch(logout());
      navigate("/login");
      toast(SERVER_ERROR);
    }

    if (!response.success) {
      toast.error(response.message);
      if (response.unauthorized) {
        dispatch(emptyFestejos());
        dispatch(logout());
        navigate("/login");
      }
      setIsLoading(false);
      return;
    }

    dispatch(editFestejo(response.festejo));
    setAmountImages([]);
    setTitle("");
    setId("");
    setDisplayModalAgregarImagenes("d-none");
    setBlur("");
    toast.success("Se agregaron las imágenes correctamente");
    setIsLoading(false);
  };

  return (
    <div
      className={"modalAgregarImagenes p-3 w-50 " + displayModalAgregarImagenes}
      style={{
        bottom:
          amountImages.length < 3
            ? "40%"
            : amountImages.length === 3
            ? "25%"
            : "20%",
      }}
    >
      <ExitButton
        setTitle={setTitle}
        setId={setId}
        setImages={setAmountImages}
        setDisplayModal={setDisplayModalAgregarImagenes}
        setBlur={setBlur}
      />
      <h4 className="d-flex justify-content-center text-center">
        Agregar imágenes a festejo: "{title}"
      </h4>
      <form
        onSubmit={(event) => {
          onSubmitAgregarImagenes(event);
        }}
        className="mt-2"
      >
        <div className="form-group mt-2">
          <label htmlFor="images" className="mb-1">
            <strong>Fotos</strong>
          </label>
          {amountImages.map((amount, index) => {
            return (
              <input
                type="file"
                className="form-control mb-1"
                name={"image" + (index + 1)}
              />
            );
          })}
        </div>
        <button type="submit" className="btn btn-success mt-2">
          Agregar
        </button>
      </form>
    </div>
  );
}

export default ModalAgregarImagenes;
